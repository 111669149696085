import { useNavigate } from "react-router-dom";
import { DocumentsView, ScreenSize, DocumentsViewMobile } from "documentdrafter-components";
import { IUserDocument } from "documentdrafter-components";
import useStore from "../../apiFunctions/store/store";
import usePersistentStore from "../../apiFunctions/store/persistentStore";
import React, { FC } from "react";
import { getEndpoints, setEndpoints, ISignalRUpdate, ICacheUser } from "documentdrafter-components";
import { IMainState } from "../ddentities";
interface IDocumentsTab {
    searchValue: string;
    setSearchValue: (newValue: string) => void;
    isLeftPaneCollapsed: boolean;
    setShowSearchbar: (show: boolean) => void;
    signalRUpdateQueue: ISignalRUpdate[];
    consumeSignalRUpdate: () => void;
    forceGoToRoot: boolean;
    shareUsers: ICacheUser[];
}

export const DocumentsTab: FC<IDocumentsTab> = (props) => {
    const navigate = useNavigate();
    const userObj = useStore().userObj;
    const documentColumnsResizeValues = usePersistentStore().documentColumnsResizeValues;
    //const signalRUpdateObj = useStore().signalRUpdate;
    const screenSize = useStore().screenSize;
    const endpoints = useStore.getState().endpoints;
    const portalConfig = useStore().portalConfig;
    setEndpoints(endpoints);

    function setDocumentColumnsResizeValues(columnId: string, width: number) {
        const resizeData = { ...documentColumnsResizeValues };
        const columnData = resizeData[columnId];

        if (columnData) {
            if (columnData.minWidth && width < columnData.minWidth) {
                width = columnData.minWidth;
            }

            columnData.defaultWidth = width;
            columnData.idealWidth = width;
        }
        usePersistentStore.setState({ documentColumnsResizeValues: resizeData });
    }

    function openYourDocument(doc: IUserDocument, versionNumber: number | undefined = undefined) {

        const mainState: IMainState = { activeTab: "mydocuments", openItem: doc.id }
        //if search is active, we dont navigate to item
        if (props.searchValue.length)
            mainState.openItem = "";
        sessionStorage.setItem("previousMainState", JSON.stringify(mainState));

        if (versionNumber) navigate(`/document/${doc.id}/${versionNumber}`);
        else navigate(`/document/${doc.id}`);
    }


    return (
        <DocumentsView
            userObj={userObj!}
            openDocument={openYourDocument}
            searchValue={props.searchValue}
            setSearchValue={props.setSearchValue}
            documentColumnsResizeValues={documentColumnsResizeValues}
            setDocumentColumnsResizeValues={setDocumentColumnsResizeValues}
            //signalRUpdatedDocument={signalRUpdateObj.userDocumentUpdated}
            //setSignalRUpdatedDocument={(value: string) => {
            //    const localSignalRUpdateObj = { ...signalRUpdateObj };
            //    localSignalRUpdateObj.userDocumentUpdated = value;
            //    useStore.setState({ signalRUpdate: localSignalRUpdateObj });
            //}}
            signalRUpdateQueue={props.signalRUpdateQueue}
            consumeSignalRUpdate={props.consumeSignalRUpdate}
            isLeftPaneCollapsed={props.isLeftPaneCollapsed}
            screenSize={screenSize}
            portalConfig={portalConfig}
            forceGoToRoot={props.forceGoToRoot}
            shareUsers={props.shareUsers}
        />
    );
};
